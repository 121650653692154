dmx.Component('typed', {

    attributes: {
        strings: {
            type: String,
            default: ''
        },

        speed: {
            type: Number,
            default: 0
        },

        delay: {
            type: Number,
            default: 0
        },

        'back-speed': {
            type: Number,
            default: 0
        },

        'back-delay': {
            type: Number,
            default: 700
        },

        'smart-backspace': {
            type: Boolean,
            default: false
        },

        shuffle: {
            type: Boolean,
            default: false
        },

        fade: {
            type: Boolean,
            default: false
        },

        loop: {
            type: Boolean,
            default: false
        },

        hidecursor: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        toggle: function() {
            this.typed.toggle();
        },

        stop: function() {
            this.typed.stop();
        },

        start: function() {
            this.typed.start();
        },

        /*reset: function(restart) {
            this.typed.reset(restart);
        }*/
    },

    events: {
        complete: Event
    },

    render: function(node) {
        this.$node = node;
        this.update({});
    },

    update: function(props) {
        if (JSON.stringify(props) != JSON.stringify(this.props)) {
            if (this.typed) this.typed.destroy();

            var strings = typeof this.props.strings == 'string' ? this.props.strings.split(',') : this.props.strings

            this.typed = new Typed(this.$node, {
                strings: strings,
                typeSpeed: this.props.speed,
                startDelay: this.props.delay,
                backSpeed: this.props['back-speed'],
                backDelay: this.props['back-delay'],
                smartBackspace: this.props['smart-backspace'],
                shuffle: this.props.shuffle,
                fadeOut: this.props.fade,
                loop: this.props.loop,
                showCursor: !this.props.hidecursor
            });
        }
    }

});
